import React, { useState } from "react"
import Layout from "../components/layout"
import { StaticQuery, graphql } from "gatsby"
import moment from "moment"
import Moment from "react-moment"
import Celebrate from "../images/celebrate.svg"

const Calendar = () => {
  const months = ["01", "02", "03", "04", "05", "06"]
  const monthNames = [
    // "September 2023",
    // "October 2023",
    // "November 2023",
    // "December 2023",
    "January 2024",
    "February 2024",
    "March 2024",
    "April 2024",
    "May 2024",
    "June 2024",
  ]
  const truecurrentMonth =
    moment().format("M").length === 1
      ? `0${moment().format("M")}`
      : moment().format("M")

  const [currentMonth, setcurrentMonth] = useState(
    months.indexOf(truecurrentMonth)
  )

  const goBackMonth = () => {
    if (currentMonth > 0) setcurrentMonth(currentMonth - 1)
  }

  const advanceMonth = () => {
    if (currentMonth < months.length - 1) setcurrentMonth(currentMonth + 1)
  }

  return (
    <StaticQuery
      query={graphql`
        query DaysClosed {
          allAirtable(filter: { table: { eq: "Calendar" } }) {
            edges {
              node {
                data {
                  Date
                  MonthText
                  WeekDay_Spanish
                  WeekDay
                  Description
                  Status
                }
              }
            }
          }
        }
      `}
      render={({ allAirtable }) => (
        <Layout>
          {console.log(allAirtable.edges)}
          <div className="max-w-7xl flex justify-center items-center flex-col pt-10 mx-auto px-3 md:px-0">
            <div className="w-full">
              <h3 className="text-4xl text-gray-600 block md:hidden text-center my-5 bg-white py-2 shadow-sm">
                {monthNames[currentMonth]}
              </h3>
              <div className="flex justify-between mb-10">
                <button
                  className={`md:ml-3 bg-ascendance text-white px-2 md:px-4 py-2 rounded-md ${
                    currentMonth === 0 && "invisible"
                  }`}
                  onClick={goBackMonth}
                >
                  Previous Month
                </button>
                <h3 className="text-4xl text-gray-700 hidden md:block">
                  {monthNames[currentMonth]}
                </h3>
                <button
                  className={`md:ml-3 bg-ascendance text-white px-2 md:px-4 py-2 rounded-md ${
                    currentMonth === 8 && "invisible"
                  }`}
                  onClick={advanceMonth}
                >
                  Next Month
                </button>
              </div>

              {allAirtable.edges
                .filter(
                  day =>
                    day.node.data.Date.split("-")[1] == months[currentMonth]
                )
                .map(day => {
                  return (
                    <div className="relative flex space-x-6 p-6 xl:static bg-white mb-4 shadow-md">
                      <div className="flex-auto">
                        <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">
                          {day.node.data.Description}
                        </h3>
                        <div className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                          <div className="flex items-start space-x-3">
                            <div className="mt-0.5">
                              <span className="sr-only">Date</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                                />
                              </svg>
                            </div>
                            <p className="mt-0.5">
                              <Moment format="dddd, MMM DD, YYYY">
                                {day.node.data.Date}
                              </Moment>
                            </p>
                            <p
                              className={`${
                                day.node.data.Status === "Closed"
                                  ? "bg-red-400"
                                  : "bg-green-500"
                              } text-white rounded px-2 px-1`}
                            >
                              {day.node.data.Status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              {allAirtable.edges.filter(
                day => day.node.data.Date.split("-")[1] == months[currentMonth]
              ).length === 0 && (
                <div className="text-center">
                  <img
                    className="max-w-xs md:max-w-xl mx-auto"
                    src={Celebrate}
                  />
                  <h3 className="text-3xl mt-5 text-gray-700">
                    We are open everyday!
                  </h3>
                </div>
              )}
            </div>
          </div>
        </Layout>
      )}
    />
  )
}

export default Calendar
